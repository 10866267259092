.app-header {
    background-color: #f0f0f0;
    border-bottom: solid 1px #ddd;

    h1 {
        font-size: 1.2em;
    }
    .header-container {

        display: flex;
        align-items: center;
        gap: 8px;

        .main-link {
            display: flex;
            align-items: center;
            gap: 8px;
            text-decoration: none;

            &:visited {
                color: initial;
            }
        }

        .spacer {
            flex: 1 1;
        }
    }

}