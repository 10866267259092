.asian-dad-workbooks {

    .header {
        background-size: cover;
        background-position: top left;

        padding: 0.5in;

        max-height: 11in;
        height: calc(100lvh - 48px - 33px);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;


        .nav {
            background: rgba(255, 255, 255, 0.8);
            padding: 2em;
            border-radius: 0.25in;

            nav {
                padding: 24px;
            }
        }
    }

}


.home {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    nav {
        min-width: 200px;
    }
    .blurb {
        flex: 1 1;
        padding: 16px;
        line-height: 1.7;
        width: 100%;
        .cta {
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2em;
        }
    }

    .hero-image {
        background-position: top left;
        background-size: cover;
        width: 100%;
        height: 30vh;
        min-height: 150px;
        margin-bottom: 48px;

        background-image: url('../assets/hackathon_header.jpg');
        
    }
}