.editor {
    border: solid 1px #ccc;
    border-radius: 6px;
    background-color: #fff;


    .ql-editor {
        min-height: 150px;
    }

    >.mdxeditor div[contenteditable=true] {
        min-height: 150px;
    }

    >.mdxeditor.editor-short {
        div[contenteditable=true] {
            min-height: 60px;

        }
    }

    p {
        min-height: 1em;
        margin-bottom: 1em;
    }

    li {
        line-height: 1;
        text-decoration: none !important;
        margin-bottom: 1em;
    }

}