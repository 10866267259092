.logo-icon {
    background-image: url('../../assets/hack-in-a-box.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;

    $ratio: calc(546/640);

    &.large {
        width: 368px;
        height: $ratio * 368px;
    }

    &.medium {
        width: 128px;
        height: $ratio * 128px;
    }

    &.small {
        height: 48px;
        width: calc(48px/$ratio);
    }
}