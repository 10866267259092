.reactions {
    button.emoji {
        border: 0;
        background: transparent;
        cursor: pointer;

        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: background-color 0.3s;
        border-radius: 8px;
        color: #555;

        .num {
            margin-left: 2px;
        }

        &.selected {
            background-color: rgba(33, 33, 33, 0.2);
            box-shadow: 0 1px 1px #adadad inset;
            .num {
                font-weight: bold;
            }
        }

        &:hover {
            background-color: rgba(88, 88, 88, 0.1);
            .tooltip {
                display: block;
            }
        }

        .tooltip {
            display: none;
            position: absolute;
            bottom: 38px;
            left: 0px;
            background: rgba(0, 0, 0, 0.8);
            color: #fff;
            padding: 5px;
            border-radius: 5px;
            font-size: 12px;
            text-align: left;
            z-index: 1;
            >div {
                white-space: nowrap;
            }
        }

    }
}