.marked {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0.5em;
    }

    p {
        min-height: 1em;
        margin-bottom: 1em;
    }
    pre {
        padding: 10px;
        background-color: #f8f8f8;
        border-radius: 5px;
        margin: 10px 0;
        overflow-x: auto;
    }

    ol,ul {
        margin-bottom: 1em;
    }
}